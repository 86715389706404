import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Mission } from 'ui/screens';

const MissionPage = ({ data, location }) => {
  return <Mission data={data} location={location}></Mission>;
};

MissionPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
};

MissionPage.defaultProps = {};

export default MissionPage;

export const MissionPageQuery = graphql`
  query MissionSectionQuery {
    allGhostPost(
      filter: { tags: { elemMatch: { name: { eq: "#mission" } } } }
      sort: { fields: [published_at], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          excerpt
          published_at
          html
          feature_image
          visibility
          tags {
            id
            name
            slug
          }
          primary_tag {
            id
            slug
          }
          authors {
            id
            slug
          }
        }
      }
    }
  }
`;
